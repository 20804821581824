import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { Button, light, dark ,ButtonProps} from '@pancakeswap-libs/uikit'
// import { Button, Card as UIKitCard, CardBody, Text } from '@pancakeswap-libs/uikit'
const CACHE_KEY = 'IS_DARK'

export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

const ThemeContext = React.createContext<ThemeContextType>({ isDark: false, toggleTheme: () => null })

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  dark.colors.primary = "white";
  // #9690a0
  // dark.colors.textSubtle = "#a4a2a9";
  dark.colors.textSubtle = "#a4a2a9";
  dark.colors.input = "#5b585f";
  dark.colors.primaryDark = "#100c18";
  dark.colors.invertedContrast = "#111015";
  dark.tooltip.text = "#9690a0";
  dark.colors.success = "#3168d0";
  // Button.defaultProps.style = {color:dark.colors.invertedContrast};
  console.log(`dark theme:`,dark);
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? dark : dark}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
